<template>
    <div class="main">
        <Sidebar active="admin" />
        <div class="main-content">
            <div class="card users">
                <div class="head-title">
                    <span class="text-title">Utilisateurs</span>
                </div>
                <perfect-scrollbar>
                    <div class="list">
                        <div
                            class="item"
                            v-for="(user, index) of users"
                            :key="user.id"
                            :class="{ active: isActive[index] }"
                            v-on:click="selectItem(index, user.id)"
                        >
                            <div>
                                <div class="text-circle">
                                    <div class="item-text">
                                        {{ user.id }} -
                                        {{ user.username }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span class="item-text">{{ user.mail }}</span>
                            </div>
                            <div>
                                <span class="item-text">{{
                                    user.organism
                                }}</span>
                            </div>
                            <div>
                                <span
                                    v-b-tooltip.hover.right="'Promouvoir'"
                                    class="item-text"
                                    v-if="user.credentials == 0"
                                    v-on:click="upgradeUser(user.id)"
                                    >Membre <b-icon-capslock></b-icon-capslock
                                ></span>
                                <span
                                    v-b-tooltip.hover.right="'Rétrograder'"
                                    class="item-text"
                                    v-if="user.credentials >= 10"
                                    v-on:click="retrogradeUser(user.id)"
                                    >Administrateur
                                    <b-icon-capslock
                                        rotate="180"
                                    ></b-icon-capslock
                                ></span>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="card templates">
                <div class="head-title">
                    <span class="text-title">Templates</span>
                    <div class="description">
                        <span class="text-description">
                            Sélectionnez un utilisateur pour modifier ses
                            templates.</span
                        >
                    </div>
                </div>
                <div class="card-body">
                    <perfect-scrollbar class="list">
                        <div class="list">
                            <div
                                class="item template"
                                v-for="template of templateOptions"
                                :key="template.value"
                            >
                                <img
                                    :src="
                                        '../template' + template.value + '.png'
                                    "
                                    class="item-img"
                                />
                                <div class="text-circle">
                                    <div class="item-text">
                                        {{ template.value }} -
                                        {{ template.text }}
                                    </div>
                                </div>
                                <b-form-checkbox
                                    v-model="templates[template.value]"
                                ></b-form-checkbox>
                            </div>
                        </div>
                    </perfect-scrollbar>
                    <b-button class="button-card" v-on:click="updateUser">
                        Sauvegarder
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Sidebar from "@/components/layouts/Sidebar.vue";
import Vue from "vue";
export default {
    components: {
        Sidebar,
    },
    data: function () {
        return {
            users: [],
            isActive: [],
            templates: [false, false, false, false],
            userSelect: "",
            templateOptions: [
                {
                    text: "Kalkin Basic",
                    value: "0",
                },
                {
                    text: "Kalkin Basic Paysage",
                    value: "3",
                },
                {
                    text: "Dômes sancy artense",
                    value: "1",
                },
                {
                    text: "Dômes sancy artense 2",
                    value: "2",
                },
            ],
        };
    },
    async beforeMount() {
        this.users = await this.$store.dispatch("user/getAllUsers");
        for (let i = 0; i < this.users.length; i++) {
            this.isActive.push(false);
        }
    },
    methods: {
        selectItem: async function (index, userId) {
            this.isActive = [];
            this.userSelect = userId;

            for (let i = 0; i < this.users.length; i++) {
                this.isActive.push(false);
            }

            this.isActive[index] = true;
            this.templates = [false, false, false, false];

            if (this.users[index].templates !== null) {
                let userTemplate = this.users[index].templates.split(",");

                if (userTemplate[0] !== "") {
                    this.templates = [];
                    for (let element of userTemplate) {
                        this.templates[element] = true;
                    }
                }
            }
        },
        upgradeUser: async function (userId) {
            let user = await this.$store.dispatch("user/updateUser", {
                id: userId,
                credentials: 10,
            });
            for (const [index, value] of this.users.entries()) {
                if (value.id === this.userSelect) {
                    Vue.set(this.users, index, user);
                }
            }
        },
        retrogradeUser: async function (userId) {
            if (userId !== this.$store.state.user.user.id) {
                let user = await this.$store.dispatch("user/updateUser", {
                    id: userId,
                    credentials: 0,
                });
                for (const [index, value] of this.users.entries()) {
                    if (value.id === this.userSelect) {
                        Vue.set(this.users, index, user);
                    }
                }
            }
        },
        updateUser: async function () {
            let tabTemplate = [];
            for (const [index, value] of this.templates.entries()) {
                if (value === true) {
                    tabTemplate.push(index);
                }
            }

            let user = await this.$store.dispatch("user/updateUser", {
                id: this.userSelect,
                templates: tabTemplate.toString(),
            });
            for (const [index, value] of this.users.entries()) {
                if (value.id === this.userSelect) {
                    this.users[index] = user;
                }
            }
        },
    },
};
</script>
<style scoped>
.main {
    display: flex;
    flex-direction: row;
    gap: 50px;
    height: 100%;
    background-color: #e5e5e5;
}
.main-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
.card {
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 100%;
}
.card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.head-title {
    display: flex;
    flex-direction: column;
    margin-left: 31px;
    margin-top: 23px;
}
.text-title {
    font-family: roboto-bold;
    font-size: 24px;
    color: #394949;
}
.card.users {
    width: 1000px;
}
.card.templates {
    width: 400px;
}
.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 17px;
    width: 100%;
}
.item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 15px rgba(182, 182, 182, 0.25);
    border-radius: 16px;
    width: 90%;
    height: 50px;
    padding-left: 16px;
    padding-right: 10px;
    cursor: pointer;
}
.item.template {
    height: 80px;
    width: 100%;
}
.item-list {
    font-size: 20px;
}
.text-color {
    color: #00817b;
}
.item-text {
    font-family: roboto-medium;
    color: #00817b;
    font-size: 16px;
}
.text-description {
    font-family: roboto-light;
    font-size: 16px;
    color: #a8bbb5;
    margin-top: 16px;
}
.item.active {
    background: #d6ebe4;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 15px rgba(182, 182, 182, 0.25);
    border-radius: 16px;
}
.item-img {
    width: 50px;
}
.button-card {
    background: #00817b;
    border-radius: 16px;
    width: 250px;
}
</style>
